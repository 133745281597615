import React from "react";
import Layout from "../layouts/page";

export default function Contact() {
  return (
    <Layout path="/contact">
      <div className="row">
        <div className="col-12 col-md-6 p-5 pl-5">
          <img src="/contact.png" className="img-fluid" alt="contact me" />
        </div>
      </div>
      <form
        className="px-5"
        method="POST"
        action="/success"
        name="contact"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="row">
          <div className="form-group col-md-4 mt-4">
            <label htmlFor="name-input">Name</label>
            <input
              type="text"
              className="form-control mt-2"
              id="name-input"
              name="name"
              placeholder="Titus Burgess"
              required
            />
          </div>
          <div className="form-group col-md-4 mt-4">
            <label htmlFor="email-input">Email</label>
            <input
              type="email"
              className="form-control mt-2"
              id="email-input"
              name="email"
              placeholder="titus@starontherise.com"
              required
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="form-group col-md-8">
            <label htmlFor="subject-input">Subject</label>
            <input
              type="text"
              className="form-control mt-2"
              id="subject-input"
              name="subject"
              placeholder="Broadway Star"
              required
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="form-group col-md-8">
            <label htmlFor="message-input" className="hidden">
              Message
            </label>
            <textarea
              className="form-control"
              id="message-input"
              name="message"
              placeholder="Message here"
              rows="3"
              required
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="form-group offset-md-4 col-md-4 push-right">
            <button type="submit" className="btn btn-purple">
              Send Now
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
}
